export const initSidebarState = {
  verify: {
    expenses: {
      pendingVerify: 0,
      pendingVoucherUpload: 0,
    },
    cancelBooking: {
      pendingCancel: 0,
    },
    pod: {
      pendingPod: 0,
    },

    verifyStock: {
      pendingStock: 0,
    },
    transactions: {
      pettyCash: 0,
    },
    verifyShortage: {
      pendingShortage: 0,
    },
  },
  cameraStatusDetails : null,
  issuesPendingCount : 0,
  complaintChatCount : {
    _id:null,
    totalCount: 0,
    readByCount: 0,
    totalMessagesCount : 0,
    unreadCount : 0,
    zeroMessages : 0,
    totalUnread : 0,
  },
  operations: {
    loading: {
      allCount: 0,
      inners: [0, 0, 0],
    },
    unloading: {
      allCount: 0,
      inners: [0, 0, 0],
    },
    delivery: {
      allCount: 0,
      inners: [0, 0, 0],
    },
    outforDelivery: {
      allCount: 0,
      inners: [0, 0, 0],
    },
    pendingPOD: {
      allCount: 0,
      inners: [0, 0, 0],
    },
    verifyStock: {
      allCount: 0,
      inners: [0, 0, 0],
    },
  },
  RequestOperations: {
    BTH: {
      allCount: 0,
      inners: [0, 0, 0],
    },
    ND: {
      allCount: 0,
      inners: [0, 0, 0],
    },
    demurrage: {
      allCount: 0,
      inners: [0, 0, 0],
    },
    remarks: {
      allCount: 0,
      inners: [0, 0, 0],
    },
    discount: {
      allCount: 0,
      inners: [0, 0, 0],
    },
    doorDeliveryDiscount:{
      allCount: 0,
      inners: [0, 0, 0],
    },
    customerConnect: {
      allCount: 0,
      inners: [0, 0, 0],
    },
    claim: { allCount: 0, inners: [0, 0, 0] },
    fleetOrderRecheckIn: {
      allCount: 0,
      inners: [0, 0, 0],
    },
    parcelTransfer: {
      allCount: 0,
      inners: [0, 0, 0],
    },
    cancelBooking: {
      allCount: 0,
      inners: [0, 0, 0],
    },
    cancelDoorDeliveryBooking: {
      allCount: 0,
      inners: [0, 0, 0],
    },
    branchDelivery: {
      allCount: 0,
      inners: [0, 0, 0]
    },
    packageUpdate: {
      allCount: 0,
      inners: [0, 0, 0]
    }
  },
  graceCount: {
    company: 0,
    user: 0,
    creditCoustomer: 0,
    branch: 0,
    fleet: 0,
  },
  creditSettlementCount: {
    amount: 0,
  },
  feedbackCount: {
    branchWiseCount: 0,
    userWiseCount: 0,
    roleWiseCount: 0,
  },
};

export const UPDATE_VERIFY_EXPENSE_BADGES =
  "SIDEBAR:UPDATE_VERIFY_EXPENSE_BADGES";
export const UPDATE_VERIFY_CANCEL_BOOKING =
  "SIDEBAR:UPDATE_VERIFY_CANCEL_BOOKING";
export const UPDATE_POD_COUNT = "SIDEBAR:UPDATE_POD_COUNT";
export const UPDATE_VERIFY_STOCK = "SIDEBAR:UPDATE_VERIFY_STOCK";
export const UPDATE_PETTY_CASH = "SIDEBAR:UPDATE_PETTY_CASH";
export const UPDATE_COMPLAINTS_CHAT = "SIDEBAR:UPDATE_COMPLAINTS_CHAT";
export const CAMERA_STATUS_DETAILS = "SIDEBAR:CAMERA_STATUS_DETAILS";
export const ISSUES_PENDING_COUNT = "SIDEBAR:ISSUES_PENDING_COUNT";
export const UPDATE_VERIFY_SHORTAGE = "SIDEBAR:UPDATE_VERIFY_SHORTAGE";
export const UPDATE_OPERATIONS_ALL_COUNT =
  "SIDEBAR:UPDATE_OPERATIONS_ALL_COUNT";
export const UPDATE_OPERATIONS_LOADING_COUNT =
  "SIDEBAR:UPDATE_OPERATIONS_LOADING_COUNT";
export const UPDATE_OPERATIONS_UNLOADING_COUNT =
  "SIDEBAR:UPDATE_OPERATIONS_UNLOADING_COUNT";
export const UPDATE_OPERATIONS_DELIVERY_COUNT =
  "SIDEBAR:UPDATE_OPERATIONS_DELIVERY_COUNT";
export const UPDATE_OPERATIONS_PENDINGPOD_COUNT =
  "SIDEBAR:UPDATE_OPERATIONS_PENDINGPOD_COUNT";
export const UPDATE_OPERATIONS_VERIFYSTOCKS_COUNT =
  "SIDEBAR:UPDATE_OPERATIONS_VERIFYSTOCKS_COUNT";
export const RESET_OPERATIONS_COUNT = "SIDEBAR:RESET_OPERATIONS_COUNT";

export const UPDATE_REQUEST_OPERATION = "SIDEBAR:UPDATE_REQUEST_OPERATION";
export const UPDATE_CREDIT_SETTLEMENT_COUNT =
  "SIDEBAR:UPDATE_CREDIT_SETTLEMENT_COUNT";
export const GET_GRACETOWER_COUNT = "SIDEBAR:GET_GRACETOWER_COUNT";
export const FEEDBACK_COUNT = "SIDEBAR:FEEDBACK_COUNT";

interface UpdateVerifyExpenseBadgeAction {
  type: typeof UPDATE_VERIFY_EXPENSE_BADGES;
  payload: {
    pendingVerify: number;
    pendingVoucherUpload: number;
  };
}
interface UpdateVerifyCancelBadgeAction {
  type: typeof UPDATE_VERIFY_CANCEL_BOOKING;
  payload: number;
}
interface UpdatePodCountAction {
  type: typeof UPDATE_POD_COUNT;
  payload: number;
}
interface UpdateVerifyStockAction {
  type: typeof UPDATE_VERIFY_STOCK;
  payload: number;
}

interface UpdatePettyCashAction {
  type: typeof UPDATE_PETTY_CASH;
  payload: number;
}
interface UpdateComplaintsChat {
  type: typeof UPDATE_COMPLAINTS_CHAT;
  payload: any;
}
interface ComplaintStatusDetails {
  type: typeof CAMERA_STATUS_DETAILS;
  payload: any;
}
interface IssuesCount {
  type: typeof ISSUES_PENDING_COUNT;
  payload: any;
}

interface UpdateVerifyShortageAction {
  type: typeof UPDATE_VERIFY_SHORTAGE;
  payload: number;
}
interface UpdateOperationsAllCountAction {
  type: typeof UPDATE_OPERATIONS_ALL_COUNT;
  payload: typeof initSidebarState["operations"];
}
interface UpdateOperationsLoadingCountAction {
  type: typeof UPDATE_OPERATIONS_LOADING_COUNT;
  payload: {
    loading: typeof initSidebarState["operations"]["loading"];
  };
}
interface UpdateOperationsUnloadingCountAction {
  type: typeof UPDATE_OPERATIONS_UNLOADING_COUNT;
  payload: {
    unloading: typeof initSidebarState["operations"]["unloading"];
  };
}
interface UpdateOperationsDeliveryCountAction {
  type: typeof UPDATE_OPERATIONS_DELIVERY_COUNT;
  payload: {
    delivery: typeof initSidebarState["operations"]["delivery"];
    outforDelivery: typeof initSidebarState["operations"]["outforDelivery"];
  };
}
interface UpdateOperationsPendingPodCountAction {
  type: typeof UPDATE_OPERATIONS_PENDINGPOD_COUNT;
  payload: {
    pendingPOD: typeof initSidebarState["operations"]["pendingPOD"];
  };
}
interface UpdateOperationsVerifyStockCountAction {
  type: typeof UPDATE_OPERATIONS_VERIFYSTOCKS_COUNT;
  payload: {
    verifyStock: typeof initSidebarState["operations"]["verifyStock"];
  };
}
interface UpdateOperationsResetCountAction {
  type: typeof RESET_OPERATIONS_COUNT;
}

interface OperationsRequestCount {
  type: typeof UPDATE_REQUEST_OPERATION;
  payload: {
    BTH: typeof initSidebarState["RequestOperations"]["BTH"];
    ND: typeof initSidebarState["RequestOperations"]["ND"];
    demurrage: typeof initSidebarState["RequestOperations"]["demurrage"];
    remarks: typeof initSidebarState["RequestOperations"]["remarks"];
    discount: typeof initSidebarState["RequestOperations"]["discount"];
    doorDeliveryDiscount: typeof initSidebarState["RequestOperations"]["doorDeliveryDiscount"];
    customerConnect: typeof initSidebarState["RequestOperations"]["customerConnect"];
    claim: typeof initSidebarState["RequestOperations"]["claim"];
    parcelTransfer: typeof initSidebarState["RequestOperations"]["parcelTransfer"];
    cancelBooking: typeof initSidebarState["RequestOperations"]["cancelBooking"];
    cancelDoorDeliveryBooking: typeof initSidebarState["RequestOperations"]["cancelDoorDeliveryBooking"];
    fleetOrderRecheckIn: typeof initSidebarState["RequestOperations"]["fleetOrderRecheckIn"];
    branchDelivery: typeof initSidebarState["RequestOperations"]["branchDelivery"];
    packageUpdate: typeof initSidebarState["RequestOperations"]["packageUpdate"];
  };
}

interface CreditSettlementCount {
  type: typeof UPDATE_CREDIT_SETTLEMENT_COUNT;
  payload: {
    amount: typeof initSidebarState["creditSettlementCount"]["amount"];
  };
}

interface graceToweCount {
  type: typeof GET_GRACETOWER_COUNT;
  payload: {
    company: typeof initSidebarState["graceCount"]["company"];
    user: typeof initSidebarState["graceCount"]["user"];
    creditCoustomer: typeof initSidebarState["graceCount"]["creditCoustomer"];
    branch: typeof initSidebarState["graceCount"]["branch"];
    fleet: typeof initSidebarState["graceCount"]["fleet"];
  };
}

interface feedbackCount {
  type: typeof FEEDBACK_COUNT;
  payload: any;
}

export type SidebarBadgesActionTypes =
  | UpdateVerifyExpenseBadgeAction
  | UpdateVerifyCancelBadgeAction
  | UpdatePodCountAction
  | UpdatePettyCashAction
  | UpdateComplaintsChat
  | ComplaintStatusDetails
  | IssuesCount
  | UpdateVerifyStockAction
  | UpdateVerifyShortageAction
  | UpdateOperationsAllCountAction
  | UpdateOperationsLoadingCountAction
  | UpdateOperationsUnloadingCountAction
  | UpdateOperationsDeliveryCountAction
  | UpdateOperationsPendingPodCountAction
  | UpdateOperationsVerifyStockCountAction
  | UpdateOperationsResetCountAction
  | OperationsRequestCount
  | graceToweCount
  | CreditSettlementCount
  | feedbackCount;
