import {
  UPDATE_VERIFY_EXPENSE_BADGES,
  SidebarBadgesActionTypes,
  initSidebarState,
  UPDATE_VERIFY_CANCEL_BOOKING,
  UPDATE_POD_COUNT,
  UPDATE_VERIFY_STOCK,
  UPDATE_VERIFY_SHORTAGE,
  UPDATE_OPERATIONS_ALL_COUNT,
  UPDATE_OPERATIONS_LOADING_COUNT,
  UPDATE_OPERATIONS_UNLOADING_COUNT,
  UPDATE_OPERATIONS_DELIVERY_COUNT,
  UPDATE_OPERATIONS_PENDINGPOD_COUNT,
  UPDATE_OPERATIONS_VERIFYSTOCKS_COUNT,
  RESET_OPERATIONS_COUNT,
  UPDATE_REQUEST_OPERATION,
  UPDATE_CREDIT_SETTLEMENT_COUNT,
  GET_GRACETOWER_COUNT,
  UPDATE_PETTY_CASH,
  FEEDBACK_COUNT,
  UPDATE_COMPLAINTS_CHAT,
  CAMERA_STATUS_DETAILS,
  ISSUES_PENDING_COUNT
} from "../constants/sidebarBadges";

type SidebareBadgesState = typeof initSidebarState;

export default (
  state = initSidebarState,
  action: SidebarBadgesActionTypes
): SidebareBadgesState => {
  switch (action.type) {
    case UPDATE_VERIFY_EXPENSE_BADGES: {
      return {
        ...state,
        verify: {
          ...state.verify,
          expenses: {
            pendingVerify: action.payload.pendingVerify,
            pendingVoucherUpload: action.payload.pendingVoucherUpload,
          },
        },
      };
    }
    case UPDATE_VERIFY_CANCEL_BOOKING: {
      return {
        ...state,
        verify: {
          ...state.verify,
          cancelBooking: {
            pendingCancel: action.payload,
          },
        },
      };
    }
    case UPDATE_POD_COUNT: {
      return {
        ...state,
        verify: {
          ...state.verify,
          pod: {
            pendingPod: action.payload,
          },
        },
      };
    }
    case UPDATE_VERIFY_STOCK: {
      return {
        ...state,
        verify: {
          ...state.verify,
          verifyStock: {
            pendingStock: action.payload,
          },
        },
      };
    }
    case UPDATE_PETTY_CASH: {
      return {
        ...state,
        verify: {
          ...state.verify,
          transactions: {
            pettyCash: action.payload,
          },
        },
      };
    }
    case UPDATE_COMPLAINTS_CHAT: {
      return {
        ...state,
        complaintChatCount: action.payload,
      };
    }
    case CAMERA_STATUS_DETAILS: {
      return {
        ...state,
        cameraStatusDetails: action.payload,
      };
    }
    case ISSUES_PENDING_COUNT: {
      return {
        ...state,
        issuesPendingCount: action.payload,
      };
    }
    case UPDATE_VERIFY_SHORTAGE: {
      return {
        ...state,
        verify: {
          ...state.verify,
          verifyShortage: {
            pendingShortage: action.payload,
          },
        },
      };
    }
    case FEEDBACK_COUNT: {
      return {
        ...state,
        feedbackCount: {
          branchWiseCount: action.payload.branchWiseCount,
          userWiseCount: action.payload.userWiseCount,
          roleWiseCount: action.payload.roleWiseCount,
        },
      };
    }
    case UPDATE_OPERATIONS_ALL_COUNT: {
      return {
        ...state,
        operations: {
          ...state.operations,
          ...action.payload,
        },
      };
    }
    case UPDATE_OPERATIONS_LOADING_COUNT: {
      return {
        ...state,
        operations: {
          ...state.operations,
          loading: {
            allCount: action.payload.loading.allCount,
            inners: action.payload.loading.inners,
          },
        },
      };
    }
    case UPDATE_REQUEST_OPERATION: {
      console.log("i here, redux", action.payload);
      return {
        ...state,
        RequestOperations: {
          ...state.RequestOperations,
         ...(action.payload.BTH ? {BTH: {
            allCount: action.payload.BTH.allCount,
            inners: action.payload.BTH.inners,
          }
        }:{} ),
          ...(action.payload.ND ? {ND: {
            allCount: action.payload.ND.allCount,
            inners: action.payload.ND.inners,
          }
        }:{} ),
          ...(action.payload.demurrage ? {demurrage: {
            allCount: action.payload.demurrage.allCount,
            inners: action.payload.demurrage.inners,
          }
        }:{} ),
          ...(action.payload.remarks ? {remarks: {
            allCount: action.payload.remarks.allCount,
            inners: action.payload.remarks.inners,
          }
        }:{} ),
          ...(action.payload.discount ? {discount: {
            allCount: action.payload.discount.allCount,
            inners: action.payload.discount.inners,
          }
        }:{} ),
        ...(action.payload.doorDeliveryDiscount ? {doorDeliveryDiscount: {
          allCount: action.payload.doorDeliveryDiscount.allCount,
          inners: action.payload.doorDeliveryDiscount.inners,
        }
      }:{} ),
          ...(action.payload.customerConnect ? {customerConnect: {
            allCount: action.payload.customerConnect.allCount,
            inners: action.payload.customerConnect.inners,
          }
        }:{} ),
          ...(action.payload.claim ? {claim: {
            allCount: action.payload.claim.allCount,
            inners: action.payload.claim.inners,
          }
        }:{} ),
          ...(action.payload.parcelTransfer ? {parcelTransfer: {
            allCount: action.payload.parcelTransfer.allCount,
            inners: action.payload.parcelTransfer.inners,
          }
        }:{} ),
          ...(action.payload.cancelBooking ? {cancelBooking: {
            allCount: action.payload.cancelBooking.allCount,
            inners: action.payload.cancelBooking.inners,
          }
        }:{} ),
        ...(action.payload.cancelDoorDeliveryBooking ? {cancelDoorDeliveryBooking: {
          allCount: action.payload.cancelDoorDeliveryBooking.allCount,
          inners: action.payload.cancelDoorDeliveryBooking.inners,
        }
      }:{} ),
          ...(action.payload.fleetOrderRecheckIn ? {fleetOrderRecheckIn: {
            allCount: action.payload.fleetOrderRecheckIn.allCount,
            inners: action.payload.fleetOrderRecheckIn.inners,
          }
        }:{} ),
        ...(action.payload.branchDelivery ? {branchDelivery: {
          allCount: action.payload.branchDelivery.allCount,
          inners: action.payload.branchDelivery.inners,
          }
        }:{} ),
        ...(action.payload.packageUpdate ? {packageUpdate: {
          allCount: action.payload.packageUpdate.allCount,
          inners: action.payload.packageUpdate.inners,
          }
        }:{} ),
        },
      };
    }
    case UPDATE_CREDIT_SETTLEMENT_COUNT: {
      return {
        ...state,
        creditSettlementCount: { amount: action.payload.amount },
      };
    }
    case GET_GRACETOWER_COUNT: {
      return {
        ...state,
        graceCount: {
          company: action.payload.company,
          user: action.payload.user,
          creditCoustomer: action.payload.creditCoustomer,
          branch: action.payload.branch,
          fleet: action.payload.fleet,
        },
      };
    }
    case UPDATE_OPERATIONS_UNLOADING_COUNT: {
      return {
        ...state,
        operations: {
          ...state.operations,
          unloading: {
            allCount: action.payload.unloading.allCount,
            inners: action.payload.unloading.inners,
          },
        },
      };
    }
    case UPDATE_OPERATIONS_DELIVERY_COUNT: {
      return {
        ...state,
        operations: {
          ...state.operations,
          delivery: {
            allCount: action.payload.delivery.allCount,
            inners: action.payload.delivery.inners,
          },
          outforDelivery: {
            allCount: action.payload.outforDelivery.allCount,
            inners: action.payload.outforDelivery.inners,
          },
        },
      };
    }
    case UPDATE_OPERATIONS_PENDINGPOD_COUNT: {
      return {
        ...state,
        operations: {
          ...state.operations,
          pendingPOD: {
            allCount: action.payload.pendingPOD.allCount,
            inners: action.payload.pendingPOD.inners,
          },
        },
      };
    }
    case UPDATE_OPERATIONS_VERIFYSTOCKS_COUNT: {
      return {
        ...state,
        operations: {
          ...state.operations,
          verifyStock: {
            allCount: action.payload.verifyStock.allCount,
            inners: action.payload.verifyStock.inners,
          },
        },
      };
    }
    case RESET_OPERATIONS_COUNT: {
      return {
        ...state,
        operations: {
          ...state.operations,
          loading: {
            allCount: 0,
            inners: [0, 0, 0],
          },
          unloading: {
            allCount: 0,
            inners: [0, 0, 0],
          },
          delivery: {
            allCount: 0,
            inners: [0, 0, 0],
          },
          outforDelivery: {
            allCount: 0,
            inners: [0, 0, 0],
          },
          pendingPOD: {
            allCount: 0,
            inners: [0, 0, 0],
          },
          verifyStock: {
            allCount: 0,
            inners: [0, 0, 0],
          },
        },
      };
    }
    default: {
      return state;
    }
  }
};
